import { Injectable, inject } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import {
  Car,
  Offer,
  HydraCollection,
  HydraEntity,
  DocumentRejectionReason,
  Client,
  Notification,
  CarVersion,
  CarEntryV2,
} from "../model";
import { SubscriptionCompanyRequest } from "app/shared/model/subscription-company-request.model";
import { map, retry } from "rxjs/operators";
import {
  calculateDaysToExpiration,
  calculateUsedMonths,
  clearNumber,
} from "app/utils/utils";
import { BusinessData } from "app/shared/model/companyI-info";

@Injectable({
  providedIn: "root",
})
export class SubscriptionApiService {
  private http = inject(HttpClient);

  /**
   * Trae la entrada indicada por el iri
   * @param iri de la entrada a buscar
   */
  public getIRI<T = any>(iri: any): Observable<T> {
    return this.http.get<any>(environment.apiUrl + iri);
  }

  /**
   * Trae todos los autos disponibles en la base de datos
   */
  public getCars(page = "1"): Observable<HydraEntity[]> {
    return this.http.get<Car[]>(environment.apiUrl + "/cars", {
      params: { page },
    });
  }

  /**
   * Trae todos las ofertas disponibles en la base de datos
   */
  public getOffers(page = "1"): Observable<HydraEntity[]> {
    return this.http.get<Offer[]>(environment.apiUrl + "/offers", {
      params: { page },
    });
  }

  /**
   * Trae los datos de un Cliente en especifico
   * @param run Run del cliente a buscar
   */
  public getClient(run: string): Observable<HydraEntity> {
    const httpOptions = {
      withCredentials: true,
    };

    return this.http.get<Client>(
      environment.apiUrl + "/clients?run=" + run,
      httpOptions
    );
  }

  public getClientV2(run: string): Observable<Client> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http
      .post<Client>(
        environment.apiUrl + "/client_infos",
        {
          run: run,
        },
        httpOptions
      )
      .pipe(
        map((data) => {
          if (data.subscriptionApplications) {
            data.subscriptionApplications = data.subscriptionApplications.map(
              (item) => {
                return { ...item, createdAt: item.createdAt?.date };
              }
            );
          }
          if (data.subscriptionContracts) {
            data.subscriptionContracts = data.subscriptionContracts.map(
              (sub) => {
                const startDate = new Date(sub.startDate?.date);
                const endDate = new Date(sub.endDate?.date);
                const currentDate = new Date();
                const millisecondsPerDay = 1000 * 3600 * 24;
                const usedMonths = calculateUsedMonths(startDate);
                const daysToExpiration = calculateDaysToExpiration(endDate);
                sub.startDate = startDate;
                sub.endDate = sub.endDate?.date;
                sub.usedMonths = (
                  usedMonths > sub.periods ? sub.periods : usedMonths
                ) as string;
                sub.daysToExpiration =
                  daysToExpiration > 0 ? daysToExpiration : 0;
                sub.daysSinceStart = Math.ceil(
                  (currentDate.getTime() - startDate.getTime()) /
                    millisecondsPerDay
                );

                sub.dtes = sub.dtes.map((dte) => {
                  return { ...dte, invoicingDate: dte.invoicingDate?.date };
                });

                return sub;
              }
            );
          }
          const client = {
            ...data,
            clientID: `/clients/${data.rawId}`,
            isProfileCompany: data?.companyInfo !== null ? true : false,
            fullName: data.name + " " + data.lastName ?? "",
            phoneNumber: clearNumber(data.phoneNumber),
            smartyAdditional:
              data?.additionalApplicationDiscount?.status === "Disponible" ??
              false,
            birthDate: data?.birthDate?.date,
          } as Client;
          return client;
        })
      );
  }

  /**
   * Cancela por parte del cliente una suscripcion que sea parte del onboarding
   * @param id Id de la suscription a cancelar
   */
  public cancelSubscription(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };
    return this.http.post(
      environment.apiUrl + "/client_application_cancelations",
      {
        subscriptionApplication: "/subscription_applications/" + id,
      },
      httpOptions
    );
  }

  /**
   * Consulta el descuento de renovación asociado al cliente
   * @param id Id del Client
   */
  public discountCodeConsultation(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };
    return this.http.post(
      environment.apiUrl + "/renewal_discount_code_consultations",
      {
        client: id,
      },
      httpOptions
    );
  }

  /**
   * Cambio de estado de Siniestros
   * @param id ID de Sinister
   * @param status nuevo estado del Siniestro
   */
  public sinisterChangeStatus(id, status) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };
    return this.http.post(
      environment.apiUrl + "/sinister_status_modifications",
      {
        sinister: id,
        sinisterNewStatus: status,
      },
      httpOptions
    );
  }

  /**
   * Se cancela Siniestro por parte del cliente
   * @param id ID de Sinister
   */
  public sinisterCancellationByClient(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };
    return this.http.post(
      environment.apiUrl + "/sinister_cancellation_by_clients",
      {
        sinister: id,
      },
      httpOptions
    );
  }

  /**
   * Creacion de siniestro
   * @param id sinisters/[id] de Subscription Contract
   */
  public sinisterCreation(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };
    return this.http.post(
      environment.apiUrl + "/sinister_creations",
      {
        subscriptionContract: "/subscription_contracts/" + id,
      },
      httpOptions
    );
  }

  /**
   * Se envía un request para cambiar el email de un cliente sin verificar y enviar mail con código OTP
   * @param run del Client
   * @param email nuevo
   */
  public changeEmailContactValidation(run, email) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };
    return this.http.post(
      environment.apiUrl + "/email_contact_validation_changes",
      {
        run,
        email,
      },
      httpOptions
    );
  }

  /**
   * Se envía un request para acreditar el codigo otp
   * @param run del Client
   * @param code de verificacion
   */
  public otpContactabilityValidation(run, code) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };
    return this.http.post(
      environment.apiUrl + "/otp_contactability_validations",
      {
        run,
        code,
      },
      httpOptions
    );
  }

  /**
   * Se envía un request para cambiar validar el medio de contacto en la Sub, si es que el codigo es correcto
   * @param run del Client
   * @param code de verificacion
   */
  public subscriptionContactabilityValidation(run, code) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };
    return this.http.post(
      environment.apiUrl + "/subscription_contactability_validations",
      {
        run,
        code,
      },
      httpOptions
    );
  }

  /**
   * Envia un request de subscripcion al back.
   * @param data parametros de la request
   */
  public postSubscriptionRequest(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      environment.apiUrl + "/subscription_requests",
      data,
      httpOptions
    );
  }

  public postSubscriptionRequestCompany(
    data: SubscriptionCompanyRequest
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
    return this.http.post(
      environment.apiUrl + "/company_subscription_requests",
      data,
      httpOptions
    );
  }

  /**
   * Envia un request para avanzar el estado de siniestro desde Pendiente de Informacion.
   * @param data es un objecto con : "sinister","type","damageLevel","sinisterNumber","policeRecordNumber","subtype","actualMileage"}
   */
  public sinisterSubmissions(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      environment.apiUrl + "/sinister_submissions",
      data,
      httpOptions
    );
  }

  /**
   * Envia un request de renovacion al back.
   * @param data parametros de la request
   */
  public postSubscriptionRenewalRequest(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      environment.apiUrl + "/subscription_renewal_requests",
      data,
      httpOptions
    );
  }

  /**
   * Envia un request de suscripcion adicional al back.
   * @param data parametros de la request
   */
  public postSubscriptionAdditionalRequest(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      environment.apiUrl + "/subscription_additional_requests",
      data,
      httpOptions
    );
  }

  /**
   * Valida la contactabilidad de la subscription application del id indicado.
   * @param id de la subscription application
   * @param token de verificacion del cliente
   */
  public postContactabilityValidation(
    id: string,
    token: string
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      environment.apiUrl + "/contactability_validations",
      {
        subscriptionApplication: "/subscription_applications/" + id,
        validationToken: token,
      },
      httpOptions
    );
  }

  /**
   * Envio de solicitud de reintentar el pago de DTE con estado Pago Rechazado
   * @param id de el DTE: '/d_t_es/[ID]'
   */
  public clientPaymentRetries(id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      environment.apiUrl + "/d_t_e_payment_processes",
      {
        dte: "/d_t_es/" + id,
      },
      httpOptions
    );
  }

  /**
   * Sube un documento nuevo
   *
   * @param formData data
   */
  public uploadDocument(formData) {
    return this.http.post(environment.apiUrl + "/client_documents", formData);
  }

  /**
   * Gatilla la validación de la cédula de identidad
   *
   * @param id de la subscription application
   */
  public identityValidation(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/identity_validations",
      { subscriptionApplication: id },
      httpOptions
    );
  }

  /**
   * Sube un documento nuevo para Siniestros
   *
   * @param formData data
   */
  public uploadSinisterDocument(formData) {
    return this.http.post(environment.apiUrl + "/sinister_documents", formData);
  }

  /**
   * Valida los documentos subidos (que esten todos)
   *
   * @param id de la subscription application
   */
  public documentsValidation(id) {
    return this.http.post(environment.apiUrl + "/documents_validations", {
      subscriptionApplication: id,
    });
  }

  /**
   * Entrega todos los documents rejection reasons del back
   */
  public getDocumentRejectionReasons(): Observable<
    HydraCollection<DocumentRejectionReason>
  > {
    return this.http.get<HydraCollection<DocumentRejectionReason>>(
      environment.apiUrl + "/document_rejection_reasons",
      {
        headers: {
          Accept: "application/ld+json",
        },
      }
    );
  }

  /**
   * Se actualizan los campos typ, subtype y damageLevel de un siniestro
   */
  public sinisterModifications(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/sinister_modifications",
      data,
      httpOptions
    );
  }

  /**
   * Aprueba los documentos del id asociado
   * @param id de la subscription application
   */
  public acceptDocuments(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/accept_documents",
      {
        subscriptionApplication: "/subscription_applications/" + id,
      },
      httpOptions
    );
  }

  /**
   * Envio de comentario del cliente respecto a sus documentos
   * @param subscription es el iri de la subscripcion
   * @param comment a agregar
   */
  public sendComments(subscription, comment) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/client_entity_comment_creations",
      {
        comment,
        subscriptionApplication: subscription,
      },
      httpOptions
    );
  }

  /**
   * Obtencion de estado de contactibilidad de Cliente
   * @param run del cliente sin punto, ni guion y en minuscula
   */
  public getContactVerificationStatus(run) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.get(
      environment.apiUrl + "/client_verifications/" + run,
      httpOptions
    );
  }

  /**
   * Rechaza ciertos documentos especificos del id asociado
   * @param id de la subscription application
   * @param comments comentarios del evaluador
   * @param documents documentos a rechazar
   */
  public rejectDocuments(id, comments, documents) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/reject_documents",
      {
        subscriptionApplication: "/subscription_applications/" + id,
        comments,
        rejectedDocuments: { ...documents },
      },
      httpOptions
    );
  }

  /**
   * Obtencion de documentos requeridos segun tipo y subtipo del siniestro
   * @param type SinisterType
   * @param subtype SinisterSubtype
   */
  public sinisterRequirementDocuments(type, subtype) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/sinister_required_documentation_consultations",
      {
        type,
        subtype,
      },
      httpOptions
    );
  }

  /**
   * Rechaza TODOS los documentos del id asociado
   * @param id de la subscription application
   * @param comments comentarios del evaluador
   */
  public rejectAllDocuments(id, comments) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/reject_all_documents",
      {
        subscriptionApplication: "/subscription_applications/" + id,
        comments,
        rejectionReason: "Rechazado por validación interna de documentos",
      },
      httpOptions
    );
  }

  /**
   * Da de baja una solicitud
   * @param id de la subscription application
   * @param comments comentarios del evaluador
   */
  public manualForceLost(id, comments) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/manual_force_losts",
      {
        subscriptionApplication: "/subscription_applications/" + id,
        comments,
      },
      httpOptions
    );
  }

  /**
   * Aprobacion del cliente de la subscription application
   * @param id de la subscription application
   */
  public clientAcceptSubscription(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/client_accepts",
      {
        subscriptionApplication: "/subscription_applications/" + id,
      },
      httpOptions
    );
  }

  /**
   * Rechazo del cliente de la subscription application
   * @param id de la subscription application
   */
  public rejectSubscription(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/subscription_rejects",
      {
        subscriptionApplication: "/subscription_applications/" + id,
        rejectionReason: "Rechazado por cliente",
      },
      httpOptions
    );
  }

  /**
   * Registro de metodo de pago en una subscription application
   * @param id de la subscription application
   * @param body datos del registro de pago en Kushki
   */
  public registerPayment(id, body) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/register_payment_subscriptions",
      {
        ...body,
        subscriptionApplication: "/subscription_applications/" + id,
      },
      httpOptions
    );
  }

  /**
   * Pide al backend el envio del contrato al correo del usuario
   * @param id de la subscription application
   */
  public requestSigning(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/request_signings",
      {
        subscriptionApplication: "/subscription_applications/" + id,
      },
      httpOptions
    );
  }

  /**
   * Solicita un cambio de email y/o telefono de un cliente
   * @param id del Client
   * @param newEmail nuevo email (puede ser null)
   * @param newPhoneNumber nuevo telefono (puede ser null)
   */
  public changeContactInfo(id, newEmail, newPhoneNumber) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/change_contact_infos",
      {
        client: "/clients/" + id,
        newPhoneNumber,
        newEmail,
      },
      httpOptions
    );
  }

  /**
   * Desvincula un PaymentSubscription de un Contrado
   * @param id del PaymentSubscription
   */
  public deletePaymentSubscription(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/card_deletions",
      {
        paymentSubscription: "/payment_subscriptions/" + id,
      },
      httpOptions
    );
  }

  /**
   * Valida que el email dado en changeContactInfo es correcto y hace el cambio
   * @param id del Client
   * @param validationToken token de validacion
   */
  public updateContactInfo(id, validationToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/update_contact_infos",
      {
        client: "/clients/" + id,
        validationToken,
      },
      httpOptions
    );
  }

  /**
   * Trae las notificaciones de la sesión activa
   */
  public getNotifications(): Observable<HydraCollection<Notification>> {
    const httpOptions = {
      withCredentials: true,
    };

    return this.http.get<any>(
      environment.apiUrl + "/notifications",
      httpOptions
    );
  }

  /**
   * Cambia el valor de seen de la notificacion (id) por el entregado (seen).
   */
  public changeNotificationSeen(id: number, seen: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/merge-patch+json",
      }),
      withCredentials: true,
    };

    return this.http.patch(
      environment.apiUrl + "/notifications/" + id,
      {
        seen,
      },
      httpOptions
    );
  }

  /**
   * Cambia el valor Region, Ciudad, direccion, email y telefono de un Cliente
   */
  public changeClientAddress(id: string, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/merge-patch+json",
      }),
      withCredentials: true,
    };

    return this.http.patch(
      environment.apiUrl + "/clients/" + id,
      data,
      httpOptions
    );
  }

  /**
   * Cambia el valor de la carpeta de la notificacion (id) por el entregado (folder).
   */
  public changeNotificationFolder(id: number, folder: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/merge-patch+json",
      }),
      withCredentials: true,
    };

    return this.http.patch(
      environment.apiUrl + "/notifications/" + id,
      {
        folder: folder === "archived" ? "Archivado" : "Principal",
      },
      httpOptions
    );
  }

  /**
   * Inicia el proceso de suscripcion de pago
   */
  public paymentProcessInitialization(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/payment_process_initializations",
      {
        subscriptionApplication: "/subscription_applications/" + id,
      },
      httpOptions
    );
  }

  /**
   * Inicia el proceso de suscripcion de pago manual
   */
  public paymentProcessInitializationManual(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/payment_subscription_skips",
      {
        subscriptionApplication: "/subscription_applications/" + id,
      },
      httpOptions
    );
  }

  /**
   * Inicia el proceso para agregar una nueva tarjeta
   * @param id de Contract
   */
  public paymentSubscriptionCreation(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/payment_subscription_creations",
      {
        subscriptionContract: "/subscription_contracts/" + id,
      },
      httpOptions
    );
  }

  /**
   * Verifica que el ingreso del medio de pago en onboarding concluya y tenga éxito
   * @param id de SubscriptionApplication
   * @param token corresponde a TBK_TOKEN
   * @param validationToken correspondiente a la Solicitud
   */
  public paymentSubscriptionFirstTimeCreation(id, token, validationToken) {
    return this.http.get(
      environment.apiUrl +
        "/payment_subscription_creation/" +
        id +
        "?validationToken=" +
        validationToken +
        "&TBK_TOKEN=" +
        token
    );
  }

  /**
   * Consula el pricing del los vehiculos que estan en el catalogo, aplicando un codigo de descuento
   * @param discountCode como código de descuentos
   * @param referredCode como código de referidos
   */
  public cataloguePricingDiscountApplication(
    discountCode,
    referredCode
  ): Observable<CarEntryV2[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post<CarEntryV2[]>(
      environment.apiUrl + "/catalogue_pricing_discount_applications",
      {
        discountCode,
        referredCode,
      },
      httpOptions
    );
  }

  /**
   * Revisa si el codigo de referidos es valido y entrega el pricing con el descuento
   */
  public referralDiscountApplication(code, carVersion: CarVersion) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      environment.apiUrl + "/referral_discount_applications",
      {
        code,
        carVersion: "/car_versions/" + carVersion.slug,
      },
      httpOptions
    );
  }

  /**
   * Revisa si un código de descuento es valido
   */
  public pricingDiscountApplication(code, run, car: Car) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    run = undefined === run ? null : run;

    return this.http.post(
      environment.apiUrl + "/pricing_discount_applications",
      {
        code,
        run,
        car: "/cars/" + car.slug,
      },
      httpOptions
    );
  }

  /**
   * Cambio de tarjeta principal
   * @param id de paymentSubscription
   */
  public primaryCardModification(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/primary_card_modifications",
      {
        paymentSubscription: "/payment_subscriptions/" + id,
      },
      httpOptions
    );
  }

  /**
   * @param id de subhscriptionApplication
   */
  public sendManualEvaluationRequest(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/manual_evaluation_requests",
      {
        subscriptionApplication: "/subscription_applications/" + id,
      },
      httpOptions
    );
  }

  /**
   * @param id de subhscriptionApplication
   * @param typeEvaluation Manual|Automatica
   */
  public riskEvaluationTypeModifications(id, typeEvaluation) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };

    return this.http.post(
      environment.apiUrl + "/risk_evaluation_type_modifications",
      {
        subscriptionApplication: "/subscription_applications/" + id,
        type: typeEvaluation,
      },
      httpOptions
    );
  }

  /**
   * getClientTag
   */
  public getClientTag(startDate, endDate, page, id): Observable<any> {
    const httpOptions = {
      withCredentials: true,
    };

    return this.http.get(
      environment.apiUrl +
        "/subscription_contracts/" +
        id +
        "/tag_registers" +
        "?page=" +
        page +
        "&itemsPerPage=" +
        30 +
        "&date[before]=" +
        endDate +
        "&date[after]=" +
        startDate,
      httpOptions
    );
  }

  public postClientTagTotal(id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      environment.apiUrl + "/tag_register_totals",
      {
        subscriptionContract: "/subscription_contracts/" + id,
      },
      httpOptions
    );
  }

  public postClientTagExcel(
    id: string,
    startDate: Date,
    endDate: Date
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      environment.apiUrl + "/tag_register_entries",
      {
        subscriptionContract: "/subscription_contracts/" + id,
        startDate: startDate,
        endDate: endDate,
      },
      httpOptions
    );
  }

  /**
   * Trae el Catalogo y se modifican aqui algunas variables para que su uso sea igual en todo lado
   */
  public getAllCatalog(): Observable<CarEntryV2[]> {
    return this.http.get<CarEntryV2[]>(environment.apiUrl + "/catalogue_cars");
  }

  public getCarDetail(car: string): Observable<any> {
    const url = `${environment.apiUrl}/catalogue_cars?${car}`;
    return this.http.get<any>(url);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public postCarDetailDiscount({
    code,
    car,
    carVersion,
    run,
  }): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const requestBody = {
      code,
      car,
      carVersion,
      run,
    };
    return this.http.post<any>(
      environment.apiUrl +
        "/pricing_discount_applications?cacheBuster=" +
        new Date().getTime(),
      requestBody,
      { headers }
    );
  }

  /**
   * Revisa si el codigo de referidos es valido y entrega el pricing con el descuento
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public postReferralDiscountApplication({
    code,
    carVersion,
    car,
  }): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(
      environment.apiUrl +
        "/referral_discount_applications?cacheBuster=" +
        new Date().getTime(),
      {
        code,
        carVersion,
        car,
      },
      httpOptions
    );
  }

  public getIndustrialSectors(): Observable<string[]> {
    return this.http
      .get<string[]>(environment.apiUrl + "/industrial_sector_enums")
      .pipe(
        map((response) => {
          if (response["hydra:member"] && response["hydra:member"].length > 0) {
            return response["hydra:member"][0].values;
          }
          return [];
        })
      );
  }

  public getCompanyAge(): Observable<string[]> {
    return this.http
      .get<string[]>(environment.apiUrl + "/company_age_enums")
      .pipe(
        map((response) => {
          if (response["hydra:member"] && response["hydra:member"].length > 0) {
            return response["hydra:member"][0].values;
          }
          return [];
        })
      );
  }

  public getCompanyInfo(id: string): Observable<BusinessData> {
    return this.http
      .post<BusinessData>(
        environment.apiUrl + "/line_of_business_consultations",
        { rut: id }
      )
      .pipe(retry(3));
  }

  public postTransferContract(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };
    return this.http.post(
      `${environment.apiUrl}/partner_migration_requests`,
      {
        subscriptionContract: `/subscription_contracts/${id}`,
      },
      httpOptions
    );
  }

  public pacthSubscriptionContract({ id, body }) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/merge-patch+json",
      }),
      withCredentials: true,
    };
    return this.http.patch(
      `${environment.apiUrl}/subscription_contracts/${id}`,
      body,
      httpOptions
    );
  }
}
