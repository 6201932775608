import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

@Injectable()
export class Saber {
  public init(): void {
    if (environment.saberEnabled) {
      (function () {
        window["Saber"] = {
          apiKey: environment.saberApiKey,
          com: [],
          do: function () {
            // eslint-disable-next-line prefer-rest-params
            this.com.push(arguments);
          },
        };
        const e = document.createElement("script");
        e.setAttribute("type", "text/javascript");
        e.setAttribute("src", "https://widget.saberfeedback.com/v2/widget.js");
        document.getElementsByTagName("head")[0].appendChild(e);
      })();
    }
  }
}
