import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AirtableService {
  private http = inject(HttpClient);

  /**
   * Trae registros de Blog desde Airtable con cache de AWS
   * @param slug nombre de la entrada
   * @param maxRecords cantidad de registros en la respuesta
   */
  public getBlogEntries(slug = "", maxRecords = 100) {
    let formula = "";
    if (slug !== "") {
      formula = "({slug}='" + slug + "')";
    }

    return this.http.get<any>("https://d3byomg4kvtypn.cloudfront.net/Blog", {
      headers: {
        "x-airtable-application-id": "Blog",
      },
      params: {
        filterByFormula: formula,
        maxRecords: String(maxRecords),
      },
    });
  }

  /**
   * Trae registros Autos desde Airtable con cache de AWS
   * @param ids Ids a buscar
   * @param maxRecords cantidad de registros en la respuesta
   */
  public getCars(ids: string[] = [], maxRecords = 100): Observable<any> {
    return this.http.get<any>("https://d3byomg4kvtypn.cloudfront.net/Autos", {
      headers: {
        "x-airtable-application-id": "Autos",
      },
      params: {
        filterByFormula: this.constructIN(ids),
        maxRecords: String(maxRecords),
      },
    });
  }

  /**
   * Trae entradas Ofertas del airtable con cache de AWS
   * @param onlySpecial true si solo se quieren los destacados
   * @param ids ids a buscar
   * @param maxRecords cantidad de registros en la respuesta
   */
  public getOffers(
    onlySpecial = false,
    ids: string[] = [],
    maxRecords = 100
  ): Observable<any> {
    let formula = "AND(NOT({status}='Inactivo'),+" + this.constructIN(ids);
    if (onlySpecial) {
      formula = formula + ",{Destacado}=TRUE())";
    } else {
      formula = formula + ")";
    }

    return this.http.get<any>("https://d3byomg4kvtypn.cloudfront.net/Ofertas", {
      headers: {
        "x-airtable-application-id": "Ofertas",
      },
      params: {
        filterByFormula: formula,
        maxRecords: String(maxRecords),
      },
    });
  }

  /**
   * Trae entradas Solicitudes del airtable con cache de AWS
   * @param ids ids a buscar
   * @param maxRecords cantidad de registros en la respuesta
   */
  public getRequests(ids: string[] = [], maxRecords = 100): Observable<any> {
    const formula =
      "AND(NOT({status}='Inactivo'),+" + this.constructIN(ids) + ")";

    return this.http.get<any>(
      "https://api.airtable.com/v0/appp1JscYM1sXQT1x/Solicitudes",
      {
        headers: {
          Authorization: "Bearer key3kfpExxE197jlj",
          "x-airtable-application-id": "Solicitudes",
        },
        params: {
          filterByFormula: formula,
          maxRecords: String(maxRecords),
        },
      }
    );
  }

  /**
   * Esta funcion genera un string para el filterByFormula de AirTable para buscar por ciertos ids en una consulta.
   * @param ids a buscar
   */
  public constructIN(ids: string[]): string {
    if (ids.length === 0) {
      return "TRUE()";
    }
    let ans = "OR(" + "RECORD_ID()='" + ids.shift() + "'";
    for (const id of ids) {
      ans = ans + ",RECORD_ID()='" + id + "'";
    }

    return ans + ")";
  }
}
