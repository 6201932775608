import { SharedService } from "app/shared/services/shared.service";
import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BrowserService } from "./browser.service";
import { CookieService } from "ngx-cookie-service";
import { environment } from "environments/environment";

export interface TokenInfo {
  alg: string;
  typ: string;
}

export interface TokenData {
  exp: number;
  iat: number;
  roles: string[];
  username: string;
}

export interface TokenValue {
  info: TokenInfo;
  data: TokenData;
  extra: string;
}

@Injectable()
export class AuthService {
  private http = inject(HttpClient);
  private browserService = inject(BrowserService);
  private cookieService = inject(CookieService);
  private sharedService = inject(SharedService);

  /**
   * @param username de usuario
   * @param password de usuario
   */
  public login(username: string, password: string) {
    return this.http.post(
      environment.apiUrl + "/login_check",
      {
        username,
        password,
      },
      {
        withCredentials: true,
      }
    );
  }

  public refreshTokenLogin() {
    return this.http.post(
      environment.apiUrl + "/login_check_refresh",
      {
        refresh_token: this.getRefreshToken(),
      },
      {
        withCredentials: true,
      }
    );
  }

  /**
   * Guarda el token entregado en localStorage
   * @param credential token entregado por el metodo login
   */
  public registerCredential(credential: {
    token: string;
    refresh_token: string;
    switchUser: string;
  }) {
    this.setTokenType("JWT");
    this.setTokenValue(credential.token);

    if (null !== credential.refresh_token) {
      this.setRefreshTokenValue(credential.refresh_token);
    }

    if (null !== credential.switchUser) {
      this.setSwitchUser(credential.switchUser);
    }
  }

  /**
   * Limpia token_type, h_rs y h_rt del localStorage
   */
  public logout(deleteAll = false) {
    if (this.browserService.isPlatformBrowser()) {
      this.sharedService.setIsActiveClient(null);
      localStorage.removeItem("token_type");
      localStorage.removeItem("h_rs");
      localStorage.removeItem("switch_user");
      if (deleteAll) {
        localStorage.removeItem("h_rt");
      }

      // FIXME Esta linea es necesaria solo cuando las cookies estan activas
      return this.http.post(environment.apiUrl + "/logout/", [], {
        withCredentials: true,
      });
    }
  }

  /**
   * Envia la solicitud de cambio de clave al back
   * @param data parametros de la request
   * data: {username: string, existingPassword: string, newPassword: string}
   */
  public postChangePassword(data) {
    return this.http.post(environment.apiUrl + "/change_user_passwords", data, {
      withCredentials: true,
    });
  }

  /**
   * Envia la solicitud de cambio de clave por recuperacion al back
   * @param data parametros de la request
   * data: {username: string, newUserPassword: string, recoveryToken: string}
   */
  public postChangePasswordByRecovery(data) {
    return this.http.post(environment.apiUrl + "/recover_user_passwords", data);
  }

  /**
   * Envia la notifricacion de cambio de clave por recuperacion al back
   * @param data parametros de la request
   * data: {username: string}
   */
  public postForgottenPassword(data) {
    return this.http.post(environment.apiUrl + "/forgotten_passwords", data);
  }

  /**
   * Entrega el tipo de token almacenado en localStorage
   */
  public getTokenType() {
    if (this.browserService.isPlatformBrowser()) {
      return localStorage.getItem("token_type") || "Bearer";
    }

    return null;
  }

  /**
   * Guarda el valor del token type en el localStorage
   * @param value del token type a guardar
   */
  public setTokenType(value: string) {
    if (this.browserService.isPlatformBrowser()) {
      localStorage.setItem("token_type", value);
    }
  }

  /**
   * Entrega el valor del token almacenado en localStorage
   */
  public getToken() {
    if (this.browserService.isPlatformBrowser()) {
      return localStorage.getItem("h_rs") || null;
    } else {
      return null;
    }
  }

  /**
   * Guarda el valor del token en el localStorage
   * @param value valor a guardar
   */
  public setTokenValue(value: string) {
    if (this.browserService.isPlatformBrowser()) {
      localStorage.setItem("h_rs", value);
    }
  }

  /**
   * Entrega el rut del usuario a impersonar
   */
  public getSwitchUser() {
    if (this.browserService.isPlatformBrowser()) {
      return localStorage.getItem("switch_user") || null;
    } else {
      return null;
    }
  }

  /**
   * Guarda el user a impersonar
   * @param value valor a guardar
   */
  public setSwitchUser(value: string) {
    if (this.browserService.isPlatformBrowser()) {
      localStorage.setItem("switch_user", value);
    }
  }

  /**
   * Entrega el valor del token almacenado en localStorage
   */
  public getRefreshToken() {
    if (this.browserService.isPlatformBrowser()) {
      return localStorage.getItem("h_rt") || null;
    } else {
      return null;
    }
  }

  /**
   * Guarda el valor del refresh token en el localStorage
   * @param value valor a guardar
   */
  public setRefreshTokenValue(value: string) {
    if (this.browserService.isPlatformBrowser()) {
      localStorage.setItem("h_rt", value);
    }
  }

  /**
   * @return TokenValue el token separada por secciones y decodificada en las primeras dos en formato JSON
   */
  public getUserValues(): TokenValue {
    if (this.getToken() !== "" && this.getToken() !== null) {
      const values = this.getToken().toString().split(".");
      return {
        info: JSON.parse(atob(values[0])),
        data: JSON.parse(atob(values[1])),
        extra: values[2],
      };
    }

    return null;
  }

  /**
   * Entrega el username asociado al token de autenticacion
   */
  public getUsername() {
    if (this.browserService.isPlatformBrowser()) {
      if (
        localStorage.getItem("switch_user") !== "undefined" &&
        localStorage.getItem("switch_user") !== undefined
      ) {
        return localStorage.getItem("switch_user");
      }
    }

    const tokenValue = this.getUserValues();
    if (tokenValue) {
      return tokenValue.data.username;
    }

    return null;
  }

  /**
   * Entrega los roles asociado al token de autenticacion
   */
  public getRoles() {
    const tokenValue = this.getUserValues();
    if (tokenValue) {
      return tokenValue.data.roles;
    }

    return null;
  }

  /**
   * Entrega la validez del token segun su fecha de expiracion
   */
  public hasExpired() {
    const tokenValue = this.getUserValues();
    if (tokenValue) {
      const expDate = new Date(tokenValue.data.exp * 1000);
      const now = new Date();
      return expDate < now;
    }

    return false;
  }

  /**
   * Entrega true si hay una sesion activa
   */
  public isLogged() {
    if (this.browserService.isPlatformBrowser()) {
      return this.getToken() !== null && this.getTokenType() === "JWT";
    } else {
      return false;
    }
  }

  public checkLoggedClient(): void {
    if (this.browserService.isPlatformBrowser()) {
      if (this.isLogged()) {
        this.sharedService.getIsActiveClient().subscribe((ans) => {
          const client = ans;
          this.sharedService.setloggedClient(client);
          const _hsq = (window["_hsq"] = window["_hsq"] || []);
          _hsq.push([
            "identify",
            {
              email: client.email,
            },
          ]);

          this.cookieService.set(
            "user",
            JSON.stringify({
              user: client.email,
              id: client.id,
            })
          );
        });
      }
    }
  }
}
