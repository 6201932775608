import { Injectable, inject } from "@angular/core";
import { AuthService } from "./auth.service";
import { SharedService } from "./shared.service";
import { SubscriptionApiService } from "./subscription-api.service";
import { TransferStateService } from "./transfer-state.service";
import { map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { CarEntryV2 } from "app/shared/model";

@Injectable({
  providedIn: "root",
})
export class CatalogueService {
  private subscriptionApiService = inject(SubscriptionApiService);
  private transferStateService = inject(TransferStateService);
  private authService = inject(AuthService);
  private sharedService = inject(SharedService);

  private readonly CARS_VIEW_KEY = "cars-view";
  private code: string;
  private referrals: string;
  private renewalCode: string;

  /**
   * Valida que los codigos existan y hace una peticion a cataloguePricingDiscountApplication, si el codigó es invalido usara el getAllCatalog() endpoint;
   * @returns Catalogo de Vehiculos
   */
  private validationCodes(): Observable<CarEntryV2[]> {
    if (this.code || this.renewalCode || this.referrals) {
      return this.subscriptionApiService
        .cataloguePricingDiscountApplication(
          this.code || this.renewalCode,
          this.referrals
        )
        .pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map((data: any) => {
            if (data?.message) {
              return this.subscriptionApiService.getAllCatalog();
            }
            return data;
          })
        );
    }
    return this.subscriptionApiService.getAllCatalog();
  }

  /**
   * Obtiene los datos del cliente si es un usurio logueado.
   * @returns Arreglo de graphql
   */

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getCars({ code, referrals, renewalCode }): Observable<CarEntryV2[]> {
    this.code = code;
    this.referrals = referrals;
    this.renewalCode = renewalCode;

    //Buscara si es SmartyAdicional
    if (this.authService.getToken() && renewalCode === null) {
      this.sharedService.getIsActiveClient().subscribe({
        next: (data) => {
          if (data?.smartyAdditional) {
            this.code = data.additionalApplicationDiscount.code;
          }
          return this.validationCodes();
        },
      });
    }
    // Si no es SmartyAdicional ira por este lado
    if (this.code || this.renewalCode) {
      return this.validationCodes();
    }

    if (this.transferStateService.has(this.CARS_VIEW_KEY)) {
      return of(this.transferStateService.tryGetRemove(this.CARS_VIEW_KEY));
    }
    return this.subscriptionApiService.getAllCatalog();
  }
}
