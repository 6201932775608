import { Injectable } from "@angular/core";

@Injectable()
export class LocalStorageService {
  /**
   * Entrega el string del utm_source almacenado en localStorage
   */
  public getItem(itemName: string) {
    const item = localStorage.getItem(itemName) || null;
    if (item) {
      const value = JSON.parse(item);
      if (
        null === value.expiration ||
        (value.expiration && new Date() < new Date(value.expiration))
      ) {
        return value.value;
      } else {
        this.removeItem(itemName);
      }
    }
    return null;
  }

  /**
   * Guarda el valor del item en el localStorage
   * @param itemName nombre del item
   * @param value valor a guardar
   * @param expiration fecha de expiración, null si no existe expiración
   */
  public saveItem(itemName: string, value: string, expiration: Date = null) {
    if (undefined !== value && null !== value) {
      const arr = {
        value,
        expiration,
      };
      const strValue = JSON.stringify(arr);
      localStorage.setItem(itemName, strValue);
    }
  }

  public removeItem(itemName: string) {
    localStorage.removeItem(itemName);
  }
}
