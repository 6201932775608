import { Client } from "app/shared/model";

export function scrollTopConfig(): void {
  const scrollToggle = document.querySelectorAll(".scroll");

  [].forEach.call(scrollToggle, (toggle: HTMLElement) => {
    toggle.addEventListener(
      "click",
      (e: Event) => {
        e.preventDefault();

        const dataID = toggle.getAttribute("data-top-scroll");
        const dataTarget = document.querySelector("#" + dataID);
        const dataSpeed = toggle.getAttribute("data-speed");

        if (dataTarget) smoothScroll(dataTarget, parseInt(dataSpeed) || 500);
      },
      false
    );
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function smoothScroll(anchor: any, duration: number): void {
  const startLocation = window.scrollY;
  const endLocation = anchor.offsetTop;
  const distance = endLocation - startLocation;
  const increments = distance / (duration / 16);
  let stopAnimation;

  const animateScroll = () => {
    window.scrollBy(0, increments);
    stopAnimation();
  };

  if (increments >= 0) {
    stopAnimation = () => {
      const travelled = window.scrollY;
      if (
        travelled >= endLocation - increments ||
        window.innerHeight + travelled >= document.body.offsetHeight
      ) {
        clearInterval(runAnimation);
      }
    };
  } else {
    stopAnimation = () => {
      const travelled = window.scrollY;
      if (travelled <= (endLocation || 0)) {
        clearInterval(runAnimation);
      }
    };
  }

  const runAnimation = setInterval(animateScroll, 16);
}

export function dateToString(currentDate: string): string {
  const dateSplit = currentDate.slice(0, 10).replace("T", " ").split("-");
  return dateSplit[2]
    .concat("/")
    .concat(dateSplit[1])
    .concat("/")
    .concat(dateSplit[0]);
}

export function compareDates(date1: Date): number {
  const date = currenteDate();
  const dayInMillis = 24 * 3600000;
  const days1 = Math.floor(date1.getTime() / dayInMillis);
  const days2 = Math.floor(new Date(date).getTime() / dayInMillis);

  if (days1 > days2) {
    return 1;
  } else if (days1 < days2) {
    return -1;
  }
  return 0;
}

export function currenteDate(): string {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = (today.getMonth() + 1).toString().padStart(2, "0");
  const dd = today.getDate().toString().padStart(2, "0");

  return `${yyyy}-${mm}-${dd}`;
}

export function clearNumber(phoneNumber: string): string {
  const clearPhoneNumber = phoneNumber.replace(/\D/g, "");
  const prefixes = ["+56", "56"];
  for (const prefix of prefixes) {
    if (clearPhoneNumber.startsWith(prefix)) {
      return clearPhoneNumber.slice(prefix.length);
    }
  }
  return clearPhoneNumber;
}

export function hasNullOrEmptyProperty(obj: object): boolean {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== "") {
      return true;
    }
  }
  return false;
}

export function getNameUser(client: Client): string {
  if (client?.isProfileCompany) {
    const { representativeName, representativeLastName } = client.companyInfo;
    return `${representativeName} ${representativeLastName}`;
  } else {
    return `${client.name} ${client.lastName}`;
  }
}

export function calculateUsedMonths(initDate): any {
  const init = new Date(initDate);
  const now = new Date();
  const months =
    now.getMonth() -
    init.getMonth() +
    12 * (now.getFullYear() - init.getFullYear()) -
    (now.getDate() <= init.getDate() ? 1 : 0);
  return months + 1;
}

export function calculateDaysToExpiration(finishDate): any {
  const init = new Date().getTime();
  const finish = new Date(finishDate).getTime();
  return Math.ceil((finish - init) / (1000 * 3600 * 24));
}
