import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import {
  makeStateKey,
  StateKey,
  TransferState,
} from "@angular/platform-browser";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { isPlatformServer } from "@angular/common";
import { environment } from "environments/environment";

export type Serializable<T> = boolean | number | string | null | object | T;

export abstract class TransferStateConverter<T> {
  /**
   * Called by the TransferStateService to convert a serialized value to an object of type T.
   */
  abstract fromTransferState(data: Serializable<T>): T;

  /**
   * Called by the TransferStateService to convert data to a value that is serializable by TransferState.
   */
  abstract toTransferState(data: T): Serializable<T>;
}

@Injectable({
  providedIn: "root",
})
export class TransferStateService {
  /**
   * The state keys.
   */
  private keys = new Map<string, StateKey<any>>();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId,
    private readonly transferState: TransferState
  ) {}

  fetch<T>(
    key: string,
    observableInput: Observable<T>,
    defaultValue?: T,
    converter?: TransferStateConverter<T>
  ): Observable<T> {
    if (this.has(key)) {
      return of(this.get(key, defaultValue, converter)).pipe(
        tap(() => this.remove(key))
      );
    }
    return observableInput.pipe(
      tap((value) => this.set(key, value, converter))
    );
  }

  tryGetRemove<T>(
    key: string,
    defaultValue?: T | null,
    converter?: TransferStateConverter<T>
  ): T | null {
    const ret = this.get<T>(key, defaultValue, converter);
    this.remove(key);

    return ret;
  }

  has<T>(key: string): boolean {
    return this.transferState.hasKey(this.getStateKey<T>(key));
  }

  remove<T>(key: string): void {
    if (!this.has(key)) {
      return;
    }
    this.transferState.remove(this.getStateKey<T>(key));
  }

  set<T>(key: string, value: T, converter?: TransferStateConverter<T>): void {
    if (isPlatformServer(this.platformId)) {
      if (this.has(key)) {
        console.warn(
          `Setting existing value into TransferState using key: '${key}'`
        );
      }
      if (!environment.production) {
        console.log(`Storing TransferState for: '${key}'`);
      }
      this.transferState.set(
        this.getStateKey<T>(key),
        converter ? converter.toTransferState(value) : value
      );
    }
  }

  private getStateKey<T>(key: string): StateKey<T> {
    if (this.keys.has(key)) {
      return this.keys.get(key) as StateKey<T>; // Cast to StateKey<T>
    }
    this.keys.set(key, makeStateKey(key) as StateKey<any>); // Cast to StateKey<any>
    return this.keys.get(key) as StateKey<T>; // Cast to StateKey<T>
  }

  get<T>(
    key: string,
    defaultValue?: T | null,
    converter?: TransferStateConverter<T>
  ): T | null {
    if (!this.has(key)) {
      return defaultValue || null;
    }

    const value: T | null = this.transferState.get<T>(
      this.getStateKey<T>(key),
      defaultValue
    );

    return converter ? converter.fromTransferState(value) : value;
  }
}
