import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { shareReplay, tap } from "rxjs/operators";
import { environment } from "environments/environment";
import { TenantVariables } from "app/shared/model";

@Injectable({ providedIn: "root" })
export class TenantVariablesService {
  private http = inject(HttpClient);

  private tenantConfiguration$: Observable<TenantVariables>;
  // tslint:disable-next-line:variable-name
  private _tenantVariables?: TenantVariables = null;

  private tenantConfigurationValues$ = new BehaviorSubject<TenantVariables>(
    null
  );

  public initialize() {
    return this.http
      .get<TenantVariables>(`${environment.apiUrl}/get_tenant_variables/`)
      .pipe(
        tap((data) => {
          this._tenantVariables = data;
        })
      )
      .toPromise();
  }

  public loadVariables(): Observable<TenantVariables> {
    if (!this.tenantConfiguration$) {
      if (null !== this._tenantVariables) {
        this.tenantConfiguration$ = this.http
          .get<TenantVariables>(`${environment.apiUrl}/get_tenant_variables/`)
          .pipe(shareReplay(1));
      } else {
        this.tenantConfiguration$ = new Observable<TenantVariables>(
          (subscriber) => {
            subscriber.next(this._tenantVariables);
          }
        ).pipe(shareReplay(1));
      }
    }

    return this.tenantConfiguration$;
  }

  public get tenantVariables() {
    return this._tenantVariables;
  }

  startTenantVariables(): Observable<TenantVariables> {
    return this.http.get<TenantVariables>(
      `${environment.apiUrl}/get_tenant_variables/`
    );
  }

  getTenantVariables(): Observable<TenantVariables> {
    return this.tenantConfigurationValues$.asObservable();
  }

  setTenantVariables(tenantVariables: TenantVariables): void {
    this.tenantConfigurationValues$.next(tenantVariables);
  }

  getTenantVariablesValue(): TenantVariables | null {
    return this.tenantConfigurationValues$.value;
  }
}
