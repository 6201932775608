import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { shareReplay, tap } from "rxjs/operators";
import { TransferStateService } from "./transfer-state.service";
import * as Cache from "memory-cache";
import { TenantVariablesService } from "./configuration/tenant-variables-service";
import { BrowserService } from "./browser.service";

@Injectable()
export class UfService {
  private getUf$?: Observable<any> = null;

  constructor(
    private browserService: BrowserService,
    private http: HttpClient,
    private transferStateService: TransferStateService,
    private tenantVariables: TenantVariablesService
  ) {}

  public getUF() {
    if (!this.getUf$) {
      if (!this.browserService.isPlatformBrowser()) {
        const data = Cache.get("uf-data");

        if (null !== data) {
          this.getUf$ = of(data).pipe(shareReplay(1));
        }
      }

      if (!this.getUf$) {
        const tenantVariables = this.tenantVariables.getTenantVariablesValue();
        this.getUf$ = this.http.get(tenantVariables.general.ufUrl).pipe(
          tap((data) => {
            Cache.put("uf-data", data, 60 * 1000);
          }),
          shareReplay(1)
        );
      }
    }

    if (this.browserService.isPlatformBrowser()) {
      return this.getUf$;
    } else {
      return this.transferStateService.fetch("uf", this.getUf$);
    }
  }
}
