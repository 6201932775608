import { defaults } from "./defaults";

const environmentFactory = () => {
  const prodDefaults = {
    production: true,
  };

  const vars = {
    ...defaults,
    ...prodDefaults,
  };

  Object.defineProperty(vars, "apiUrl", {
    get: () => {
      if (typeof globalThis["app-environment"] === "object") {
        return globalThis["app-environment"].apiUrl;
      }

      return defaults.tapiUrl;
    },
  });

  Object.defineProperty(vars, "graphcdnUrl", {
    get: () => {
      if (typeof globalThis["app-environment"] === "object") {
        return globalThis["app-environment"].graphcdnUrl;
      }

      return defaults.graphcdnUrl;
    },
  });

  Object.defineProperty(vars, "sentryEnvironment", {
    get: () => {
      if (typeof globalThis["app-environment"] === "object") {
        return globalThis["app-environment"].sentryEnvironment;
      }

      return defaults.sentryEnvironment;
    },
  });

  if (vars.sentryEnvironment === "production") {
    vars.tagManagerKey = "GTM-P3ZCF93";
  }

  return vars;
};

export const environment = environmentFactory();
