import { Injectable } from "@angular/core";

@Injectable()
export class UtmSourceService {
  constructor() {}

  /**
   * Entrega el string del utm_source almacenado en localStorage
   */
  public getUtmSource(): string | null {
    const value = localStorage.getItem("utm_source") || null;
    if (value) {
      if (
        this.getDaysDiff(new Date(), new Date(JSON.parse(value).createdAt)) < 31
      ) {
        return value;
      } else {
        this.removeUtmSource();
      }
    }
    return null;
  }

  /**
   * Entrega el string convertido en json del utm_source almacenado en localStorage
   */
  public getUtmSourceJSON() {
    const value = this.getUtmSource();
    if (value) {
      return JSON.parse(value);
    }
    return null;
  }

  /**
   * Crea y guarda el utm_source en forma de json "stringifeado" (json.stringify)
   * @param $keys nombre de los parametros por url
   * @param $values valores de los parametros por url
   */
  public setUtmSourceJson($keys, $values) {
    const arr = {
      createdAt: new Date(),
    };

    for (const $key of $keys) {
      if ($key.includes("utm_")) {
        arr[$key] = $values[$key];
      }
    }

    // Si se agrego algo mas que la fecha, se procede como se espera
    if (1 < Object.keys(arr).length) {
      const str = JSON.stringify(arr);
      this.setUtmSource(str);
      return str;
    }

    return null;
  }

  /**
   * Guarda el valor del utm_source en el localStorage
   * @param value valor a guardar
   */
  public setUtmSource(value: string) {
    localStorage.setItem("utm_source", value);
  }

  public removeUtmSource() {
    localStorage.removeItem("utm_source");
  }

  public getDaysDiff(d1, d2) {
    const difference = d1.getTime() - d2.getTime();
    return Math.ceil(difference / (1000 * 3600 * 24));
  }
}
